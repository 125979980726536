
import { computed, defineComponent, ref } from 'vue';
import ModalButton from '@/components/util/ModalButton.vue';
import Modal from '@/components/util/Modal.vue';
import Slider from '@/components/util/Slider.vue';
import SliderItem from '@/components/util/SliderItem.vue';

export default defineComponent({
  components: {
    ModalButton,
    Modal,
    Slider,
    SliderItem,
  },
  setup() {
    const branchen = [
      'Bitte Branche auswählen...',
      'Finanzen + Versicherungen',
      'Gesundheit + Pflege',
      'Gewerbe + Handwerk + Industrie + Chemie + Land- u Forstwirtschaft',
      'Immobilien + Bauen + Wohnen',
      'Karriere + Bildung',
      'Kultur - Veranstaltungen',
      'Lifestyle - Mode - Schmuck - Uhren',
      'Mobilität',
      'Nahrungs- und Genussmittel',
      'Öffentlicher Bereich + Energie',
      'Spezialthema',
      'Tourismus + Gastronomie + Freizeitwirtschaft',
    ];
    const activeBranche = ref('Bitte Branche auswählen...');
    const monate = ['Bitte Monat auswählen...', 'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];
    const activeMonat = ref('Bitte Monat auswählen...');
    const datenSchwerpunkte = [
      {
        Monat: 'Februar',
        Branche: 'Gesundheit + Pflege',
        Themenschwerpunkt: 'Lebenslust',
        Erscheinungstermin: '12.02.2022',
        Anzeigenschluss: '02.02.2022',
        Druckunterlagenschluss: '02.02.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Februar',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Wohnen',
        Erscheinungstermin: '25.02.2022',
        Anzeigenschluss: '18.02.2022',
        Druckunterlagenschluss: '23.02.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Februar',
        Branche: 'Karriere + Bildung',
        Themenschwerpunkt: 'Lehrlinge',
        Erscheinungstermin: '26.02.2022',
        Anzeigenschluss: '16.02.2022',
        Druckunterlagenschluss: '21.02.2022',
        Typ: 'Mitdruck',
      },
      { Monat: 'Februar', Branche: 'Spezialthema', Themenschwerpunkt: 'Pausenzeitung', Erscheinungstermin: '28.02.2022', Anzeigenschluss: '27.12.2021', Druckunterlagenschluss: '10.01.2022', Typ: 'Journal' },
      {
        Monat: 'März',
        Branche: 'Lifestyle - Mode - Schmuck - Uhren',
        Themenschwerpunkt: 'Lebensstil',
        Erscheinungstermin: '02.03.2022',
        Anzeigenschluss: '18.02.2022',
        Druckunterlagenschluss: '25.02.2022',
        Typ: 'Druckstrecke',
      },
      {
        Monat: 'März',
        Branche: 'Mobilität',
        Themenschwerpunkt: 'motionexpo 2022',
        Erscheinungstermin: '04.03.2022',
        Anzeigenschluss: '18.02.2022',
        Druckunterlagenschluss: '25.02.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'März',
        Branche: 'Tourismus + Gastronomie + Freizeitwirtschaft',
        Themenschwerpunkt: 'Reisezeit',
        Erscheinungstermin: '05.03.2022',
        Anzeigenschluss: '23.02.2022',
        Druckunterlagenschluss: '23.02.2022',
        Typ: 'Mantel',
      },
      {
        Monat: 'März',
        Branche: 'Gesundheit + Pflege',
        Themenschwerpunkt: 'Lebenslust',
        Erscheinungstermin: '06.03-2022',
        Anzeigenschluss: '23.02.2022',
        Druckunterlagenschluss: '01.03.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'März',
        Branche: 'Karriere + Bildung',
        Themenschwerpunkt: 'FH Guide',
        Erscheinungstermin: '09.03.2022',
        Anzeigenschluss: '25..02.2022',
        Druckunterlagenschluss: '04.03.2022',
        Typ: 'Druckstrecke',
      },
      {
        Monat: 'März',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Bauratgeber',
        Erscheinungstermin: '16.03.2022',
        Anzeigenschluss: '18.02.2022',
        Druckunterlagenschluss: '23.02.2022',
        Typ: 'Ratgeber',
      },
      {
        Monat: 'März',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Preisspiegel Serie',
        Erscheinungstermin: '18.03.2022',
        Anzeigenschluss: '07.03.2022',
        Druckunterlagenschluss: '16.03.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'März',
        Branche: 'Gesundheit + Pflege',
        Themenschwerpunkt: 'Pflege',
        Erscheinungstermin: '20.03.2022',
        Anzeigenschluss: '09.03.2022',
        Druckunterlagenschluss: '14.03.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'März',
        Branche: 'Karriere + Bildung',
        Themenschwerpunkt: 'Recruiting Day',
        Erscheinungstermin: '23.03.2022',
        Anzeigenschluss: '11.03.2022',
        Druckunterlagenschluss: '16.03.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'März',
        Branche: 'Spezialthema',
        Themenschwerpunkt: 'Mantel Obersteiermark',
        Erscheinungstermin: '24.03.2022',
        Anzeigenschluss: '03.03.2022',
        Druckunterlagenschluss: '10.03.2022',
        Typ: 'Mantel',
      },
      {
        Monat: 'März',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Wohnen',
        Erscheinungstermin: '25.03.2022',
        Anzeigenschluss: '18.03.2022',
        Druckunterlagenschluss: '23.03.2022',
        Typ: 'Journal',
      },
      { Monat: 'März', Branche: 'Beratung & Wirtschafts- und Rechtsdienste', Themenschwerpunkt: 'Primus', Erscheinungstermin: '26.03.2022', Anzeigenschluss: '16.03.2022', Druckunterlagenschluss: '16.03.2022', Typ: 'Journal' },
      { Monat: 'März', Branche: 'Spezialthema', Themenschwerpunkt: 'Frühlings- . Sommerbeilage Kinderzeitung', Erscheinungstermin: '26.03.2022', Anzeigenschluss: '14.02.2022', Druckunterlagenschluss: '21.02.2022', Typ: 'Journal' },
      {
        Monat: 'März',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Wohnbauförderungsjournal',
        Erscheinungstermin: '27.03.2022',
        Anzeigenschluss: '01.03.2022',
        Druckunterlagenschluss: '11.03.2022',
        Typ: 'Magazin Zeitungspapier',
      },
      {
        Monat: 'März',
        Branche: 'Gewerbe + Handwerk + Industrie + Chemie + Land- u Forstwirtschaft',
        Themenschwerpunkt: 'Kärntner Vorzeigebetriebe',
        Erscheinungstermin: '29.03.2022',
        Anzeigenschluss: '16.03.2022',
        Druckunterlagenschluss: '16.03.2022',
        Typ: 'Mitdruck',
      },
      { Monat: 'April', Branche: 'Spezialthema', Themenschwerpunkt: 'Pausenzeitung', Erscheinungstermin: '04.04.2022', Anzeigenschluss: '31.01.2022', Druckunterlagenschluss: '14.02.2022', Typ: 'Journal' },
      {
        Monat: 'April',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Magazin Leben im Garten',
        Erscheinungstermin: '06.04.2022',
        Anzeigenschluss: '18.03.2022',
        Druckunterlagenschluss: '25.03.2022',
        Typ: 'Magazin Zeitungspapier',
      },
      {
        Monat: 'April',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Preisspiegel Serie',
        Erscheinungstermin: '08.04.2022',
        Anzeigenschluss: '28.03.2022',
        Druckunterlagenschluss: '06.04.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'April',
        Branche: 'Gesundheit + Pflege',
        Themenschwerpunkt: 'Lebenslust',
        Erscheinungstermin: '10.04.2022',
        Anzeigenschluss: '30.03.2022',
        Druckunterlagenschluss: '05.04.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'April',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'pro:Holz',
        Erscheinungstermin: '17.04.2022',
        Anzeigenschluss: '21.03.2022',
        Druckunterlagenschluss: '28.03.2022',
        Typ: 'Magazin LWC',
      },
      {
        Monat: 'April',
        Branche: 'Lifestyle - Mode - Schmuck - Uhren',
        Themenschwerpunkt: 'Magazin Lebensstil',
        Erscheinungstermin: '20.04.2022',
        Anzeigenschluss: '01.04.2022',
        Druckunterlagenschluss: '04.04.2022',
        Typ: 'Magazin Zeitungspapier',
      },
      {
        Monat: 'April',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Preisspiegel Serie',
        Erscheinungstermin: '22.04.2022',
        Anzeigenschluss: '11.04.2022',
        Druckunterlagenschluss: '20.04.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'April',
        Branche: 'Spezialthema',
        Themenschwerpunkt: 'Im Fokus Weststeiermark',
        Erscheinungstermin: '24.04.2022',
        Anzeigenschluss: '12.04.2022',
        Druckunterlagenschluss: '19.04.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'April',
        Branche: 'Tourismus + Gastronomie + Freizeitwirtschaft',
        Themenschwerpunkt: 'Lust auf Kärnten',
        Erscheinungstermin: '26.04.2022',
        Anzeigenschluss: '15.04.2022',
        Druckunterlagenschluss: '22.04.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'April',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Wohnen',
        Erscheinungstermin: '29.04.2022',
        Anzeigenschluss: '22.04.2022',
        Druckunterlagenschluss: '27.04.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Mai',
        Branche: 'Nahrungs- und Genussmittel',
        Themenschwerpunkt: 'Magazin Kostprobe',
        Erscheinungstermin: '01.05.2022',
        Anzeigenschluss: '11.04.2022',
        Druckunterlagenschluss: '15.04.2022',
        Typ: 'Magazin Zeitungspapier',
      },
      {
        Monat: 'Mai',
        Branche: 'Gesundheit + Pflege',
        Themenschwerpunkt: 'Lebenslust',
        Erscheinungstermin: '08.05.2022',
        Anzeigenschluss: '27.04.2022',
        Druckunterlagenschluss: '03.05.2022',
        Typ: 'Journal',
      },
      { Monat: 'Mai', Branche: 'Spezialthema', Themenschwerpunkt: 'Pausenzeitung', Erscheinungstermin: '09.05.2022', Anzeigenschluss: '07.03.2022', Druckunterlagenschluss: '21.03.2022', Typ: 'Journal' },
      {
        Monat: 'Mai',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Preisspiegel Serie',
        Erscheinungstermin: '13.05.2022',
        Anzeigenschluss: '02.05.2022',
        Druckunterlagenschluss: '11.05.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'Mai',
        Branche: 'Kultur - Veranstaltungen',
        Themenschwerpunkt: 'Kultursommer',
        Erscheinungstermin: '15.05.2022',
        Anzeigenschluss: '19.04.2022',
        Druckunterlagenschluss: '25.04.2022',
        Typ: 'Magazin Zeitungspapier',
      },
      {
        Monat: 'Mai',
        Branche: 'Karriere + Bildung',
        Themenschwerpunkt: 'Fachkräfte',
        Erscheinungstermin: '21.05.2022',
        Anzeigenschluss: '06.05.2022',
        Druckunterlagenschluss: '13.05.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Mai',
        Branche: 'Gewerbe + Handwerk + Industrie + Chemie + Land- u Forstwirtschaft',
        Themenschwerpunkt: 'Kärntner Vorzeigebetriebe',
        Erscheinungstermin: '24.05.2022',
        Anzeigenschluss: '11.05.2022',
        Druckunterlagenschluss: '11.05.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'Mai',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Wohnen',
        Erscheinungstermin: '27.05.2022',
        Anzeigenschluss: '20.05.2022',
        Druckunterlagenschluss: '25.05.2022',
        Typ: 'Journal',
      },
      { Monat: 'Mai', Branche: 'Beratung & Wirtschafts- und Rechtsdienste', Themenschwerpunkt: 'Primus', Erscheinungstermin: '28.05.2022', Anzeigenschluss: '18.05.2022', Druckunterlagenschluss: '18.05.2022', Typ: 'Journal' },
      {
        Monat: 'Juni',
        Branche: 'Lifestyle - Mode - Schmuck - Uhren',
        Themenschwerpunkt: 'Lebensstil',
        Erscheinungstermin: '01.06.2022',
        Anzeigenschluss: '20.05.2022',
        Druckunterlagenschluss: '27.05.2022',
        Typ: 'Druckstrecke',
      },
      {
        Monat: 'Juni',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Preisspiegel Serie',
        Erscheinungstermin: '03.06.2022',
        Anzeigenschluss: '23.05.2022',
        Druckunterlagenschluss: '01.06.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'Juni',
        Branche: 'Tourismus + Gastronomie + Freizeitwirtschaft',
        Themenschwerpunkt: 'Reisezeit',
        Erscheinungstermin: '04.06.2022',
        Anzeigenschluss: '24.05.2022',
        Druckunterlagenschluss: '24.05.2022',
        Typ: 'Mantel',
      },
      { Monat: 'Juni', Branche: 'Spezialthema', Themenschwerpunkt: 'Pausenzeitung', Erscheinungstermin: '07.06.2022', Anzeigenschluss: '28.04.2022', Druckunterlagenschluss: '11.05.2022', Typ: 'Journal' },
      {
        Monat: 'Juni',
        Branche: 'Gesundheit + Pflege',
        Themenschwerpunkt: 'Lebenslust',
        Erscheinungstermin: '12.06.2022',
        Anzeigenschluss: '01.06.2022',
        Druckunterlagenschluss: '07.06.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Juni',
        Branche: 'Gewerbe + Handwerk + Industrie + Chemie + Land- u Forstwirtschaft',
        Themenschwerpunkt: 'Steirische Vorzeigebetriebe',
        Erscheinungstermin: '14.06.2022',
        Anzeigenschluss: '31.05.2022',
        Druckunterlagenschluss: '31.05.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'Juni',
        Branche: 'Spezialthema',
        Themenschwerpunkt: 'Mantel Obersteiermark',
        Erscheinungstermin: '23.06.2022',
        Anzeigenschluss: '02.06.2022',
        Druckunterlagenschluss: '09.06.2022',
        Typ: 'Mantel',
      },
      {
        Monat: 'Juni',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Wohnen',
        Erscheinungstermin: '24.06.2022',
        Anzeigenschluss: '17.06.2022',
        Druckunterlagenschluss: '22.06.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Juli',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Preisspiegel Serie',
        Erscheinungstermin: '01.07.2022',
        Anzeigenschluss: '20.06.2022',
        Druckunterlagenschluss: '29.06.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'Juli',
        Branche: 'Lifestyle - Mode - Schmuck - Uhren',
        Themenschwerpunkt: 'Ida',
        Erscheinungstermin: '09.07.2022',
        Anzeigenschluss: '25.05.2022',
        Druckunterlagenschluss: '01.06.2022',
        Typ: 'Magazin LWC',
      },
      {
        Monat: 'Juli',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Wohnen',
        Erscheinungstermin: '29.07.2022',
        Anzeigenschluss: '22.07.2022',
        Druckunterlagenschluss: '27.07.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'August',
        Branche: 'Tourismus + Gastronomie + Freizeitwirtschaft',
        Themenschwerpunkt: 'Lust auf Kärnten',
        Erscheinungstermin: '17.08.2022',
        Anzeigenschluss: '05.08.2022',
        Druckunterlagenschluss: '12.08.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'August',
        Branche: 'Gewerbe + Handwerk + Industrie + Chemie + Land- u Forstwirtschaft',
        Themenschwerpunkt: 'Kärntner Vorzeigebetriebe',
        Erscheinungstermin: '23.08.2022',
        Anzeigenschluss: '10.08.2022',
        Druckunterlagenschluss: '10.08.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'August',
        Branche: 'Lifestyle - Mode - Schmuck - Uhren',
        Themenschwerpunkt: 'Lebensstil',
        Erscheinungstermin: '24.08.2022',
        Anzeigenschluss: '12.08.2022',
        Druckunterlagenschluss: '19.08.2022',
        Typ: 'Druckstrecke',
      },
      {
        Monat: 'August',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Wohnen',
        Erscheinungstermin: '26.08.2022',
        Anzeigenschluss: '19.08.2022',
        Druckunterlagenschluss: '24.08.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'September',
        Branche: 'Tourismus + Gastronomie + Freizeitwirtschaft',
        Themenschwerpunkt: 'Reisezeit',
        Erscheinungstermin: '03.09.2022',
        Anzeigenschluss: '24.08.2022',
        Druckunterlagenschluss: '24.08.2022',
        Typ: 'Mantel',
      },
      {
        Monat: 'September',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Preisspiegel Serie',
        Erscheinungstermin: '09.09.2022',
        Anzeigenschluss: '29.08.2022',
        Druckunterlagenschluss: '07.09.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'September',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Preisspiegel Serie',
        Erscheinungstermin: '09.09.2022',
        Anzeigenschluss: '29.08.2022',
        Druckunterlagenschluss: '07.09.2022',
        Typ: 'Mitdruck',
      },
      { Monat: 'September', Branche: 'Spezialthema', Themenschwerpunkt: 'Herbst- . Winterbeilage Kinderzeitung', Erscheinungstermin: '10.09.2022', Anzeigenschluss: '01.08.2022', Druckunterlagenschluss: '08.08.2022', Typ: 'Journal' },
      {
        Monat: 'September',
        Branche: 'Gesundheit + Pflege',
        Themenschwerpunkt: 'Lebenslust',
        Erscheinungstermin: '11.09.2022',
        Anzeigenschluss: '31.08.2022',
        Druckunterlagenschluss: '06.09.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'September',
        Branche: 'Gewerbe + Handwerk + Industrie + Chemie + Land- u Forstwirtschaft',
        Themenschwerpunkt: 'Steirische Vorzeigebetriebe',
        Erscheinungstermin: '13.09.2022',
        Anzeigenschluss: '30.08.2022',
        Druckunterlagenschluss: '30.08.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'September',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Preisspiegel Serie',
        Erscheinungstermin: '16.09.2022',
        Anzeigenschluss: '05.09.2022',
        Druckunterlagenschluss: '14.09.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'September',
        Branche: 'Spezialthema',
        Themenschwerpunkt: 'Mantel Obersteiermark',
        Erscheinungstermin: '22.09.2022',
        Anzeigenschluss: '01.09.2022',
        Druckunterlagenschluss: '08.09.2022',
        Typ: 'Mantel',
      },
      { Monat: 'September', Branche: 'Beratung & Wirtschafts- und Rechtsdienste', Themenschwerpunkt: 'Primus', Erscheinungstermin: '24.09.2022', Anzeigenschluss: '14.09.2022', Druckunterlagenschluss: '14.09.2022', Typ: 'Journal' },
      {
        Monat: 'September',
        Branche: 'Karriere + Bildung',
        Themenschwerpunkt: 'Wissen was geht - Südsüdwest . Ennstal',
        Erscheinungstermin: '29.09.2022',
        Anzeigenschluss: '16.09.2022',
        Druckunterlagenschluss: '14.09.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'September',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Wohnen',
        Erscheinungstermin: '30.09.2022',
        Anzeigenschluss: '23.09.2022',
        Druckunterlagenschluss: '28.09.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Oktober',
        Branche: 'Karriere + Bildung',
        Themenschwerpunkt: 'Gründermesse 2022',
        Erscheinungstermin: '01.10.2022',
        Anzeigenschluss: '16.09.2022',
        Druckunterlagenschluss: '23.09.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Oktober',
        Branche: 'Nahrungs- und Genussmittel',
        Themenschwerpunkt: 'Magazin Kostprobe',
        Erscheinungstermin: '02.10.2022',
        Anzeigenschluss: '12.09.2022',
        Druckunterlagenschluss: '16.09.2022',
        Typ: 'Magazin Zeitungspapier',
      },
      {
        Monat: 'Oktober',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Preisspiegel Serie',
        Erscheinungstermin: '07.10.2022',
        Anzeigenschluss: '26.09.2022',
        Druckunterlagenschluss: '05.10.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'Oktober',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Preisspiegel Serie',
        Erscheinungstermin: '07.10.2022',
        Anzeigenschluss: '26.09.2022',
        Druckunterlagenschluss: '05.10.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'Oktober',
        Branche: 'Gesundheit + Pflege',
        Themenschwerpunkt: 'Lebenslust',
        Erscheinungstermin: '09.10.2022',
        Anzeigenschluss: '28.09.2022',
        Druckunterlagenschluss: '04.10.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Oktober',
        Branche: 'Mobilität',
        Themenschwerpunkt: 'Innovationsmotor Mobilitätsindustrie',
        Erscheinungstermin: '12.10.2022',
        Anzeigenschluss: '07.09.2022',
        Druckunterlagenschluss: '14.09.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Oktober',
        Branche: 'Gesundheit + Pflege',
        Themenschwerpunkt: 'Pflege',
        Erscheinungstermin: '16.10.2022',
        Anzeigenschluss: '05.10.2022',
        Druckunterlagenschluss: '10.10.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Oktober',
        Branche: 'Karriere + Bildung',
        Themenschwerpunkt: 'Wissen was geht - Südost',
        Erscheinungstermin: '21.10.2022',
        Anzeigenschluss: '07.10.2022',
        Druckunterlagenschluss: '14.10.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Oktober',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Preisspiegel Serie',
        Erscheinungstermin: '21.10.2022',
        Anzeigenschluss: '10.10.2022',
        Druckunterlagenschluss: '19.10.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'Oktober',
        Branche: 'Karriere + Bildung',
        Themenschwerpunkt: 'Kompass Lehre',
        Erscheinungstermin: '22.10.2022',
        Anzeigenschluss: '30.09.2022',
        Druckunterlagenschluss: '23.09.2022',
        Typ: 'Magazin Zeitungspapier',
      },
      {
        Monat: 'Oktober',
        Branche: 'Gesundheit + Pflege',
        Themenschwerpunkt: 'Ärzteführer',
        Erscheinungstermin: '25.10.2022',
        Anzeigenschluss: '23.09.2022',
        Druckunterlagenschluss: '07.10.2022',
        Typ: 'Ratgeber',
      },
      {
        Monat: 'Oktober',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Wohnen',
        Erscheinungstermin: '28.10.2022',
        Anzeigenschluss: '20.10.2022',
        Druckunterlagenschluss: '25.10.2022',
        Typ: 'Journal',
      },
      { Monat: 'Oktober', Branche: 'Beratung & Wirtschafts- und Rechtsdienste', Themenschwerpunkt: 'Primus', Erscheinungstermin: '29.10.2022', Anzeigenschluss: '19.10.2022', Druckunterlagenschluss: '19.10.2022', Typ: 'Journal' },
      {
        Monat: 'Oktober',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'pro:Holz',
        Erscheinungstermin: '30.10.2022',
        Anzeigenschluss: '03.10.2022',
        Druckunterlagenschluss: '10.10.2022',
        Typ: 'Magazin LWC',
      },
      {
        Monat: 'November',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Preisspiegel Serie',
        Erscheinungstermin: '04.11.2022',
        Anzeigenschluss: '24.10.2022',
        Druckunterlagenschluss: '02.11.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'November',
        Branche: 'Tourismus + Gastronomie + Freizeitwirtschaft',
        Themenschwerpunkt: 'Reisezeit',
        Erscheinungstermin: '05.11.2022',
        Anzeigenschluss: '24.10.2022',
        Druckunterlagenschluss: '24.10.2022',
        Typ: 'Mantel',
      },
      {
        Monat: 'November',
        Branche: 'Gesundheit + Pflege',
        Themenschwerpunkt: 'Lebenslust',
        Erscheinungstermin: '06.11.2022',
        Anzeigenschluss: '25.10.2022',
        Druckunterlagenschluss: '31.10.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'November',
        Branche: 'Kultur - Veranstaltungen',
        Themenschwerpunkt: 'Kulturwinter',
        Erscheinungstermin: '13.11.2022',
        Anzeigenschluss: '17.10.2022',
        Druckunterlagenschluss: '24.10.2022',
        Typ: 'Magazin Zeitungspapier',
      },
      {
        Monat: 'November',
        Branche: 'Gewerbe + Handwerk + Industrie + Chemie + Land- u Forstwirtschaft',
        Themenschwerpunkt: 'Steirische Vorzeigebetriebe',
        Erscheinungstermin: '15.11.2022',
        Anzeigenschluss: '31.10.2022',
        Druckunterlagenschluss: '31.10.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'November',
        Branche: 'Spezialthema',
        Themenschwerpunkt: 'Mantel Obersteiermark',
        Erscheinungstermin: '17.11.2022',
        Anzeigenschluss: '27.10.2022',
        Druckunterlagenschluss: '03.11.2022',
        Typ: 'Mantel',
      },
      {
        Monat: 'November',
        Branche: 'Gesundheit + Pflege',
        Themenschwerpunkt: 'Themenmagazin Gesundheit',
        Erscheinungstermin: '19.11.2022',
        Anzeigenschluss: '28.10.2022',
        Druckunterlagenschluss: '04.11.2022',
        Typ: 'Magazin Zeitungspapier',
      },
      {
        Monat: 'November',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Wohnen',
        Erscheinungstermin: '25.11.2022',
        Anzeigenschluss: '18.11.2022',
        Druckunterlagenschluss: '23.11.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'November',
        Branche: 'Karriere + Bildung',
        Themenschwerpunkt: 'Top-Jobs im Süden Österreichs',
        Erscheinungstermin: '26.11.2022',
        Anzeigenschluss: '11.11.2022',
        Druckunterlagenschluss: '18.11.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'November',
        Branche: 'Spezialthema',
        Themenschwerpunkt: 'Ida',
        Erscheinungstermin: '27.11.2022',
        Anzeigenschluss: '12.10.2022',
        Druckunterlagenschluss: '19.10.2022',
        Typ: 'Magazin LWC',
      },
      {
        Monat: 'November',
        Branche: 'Gewerbe + Handwerk + Industrie + Chemie + Land- u Forstwirtschaft',
        Themenschwerpunkt: 'Kärntner Vorzeigebetriebe',
        Erscheinungstermin: '29.11.2022',
        Anzeigenschluss: '16.11.2022',
        Druckunterlagenschluss: '16.11.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'Dezember',
        Branche: 'Tourismus + Gastronomie + Freizeitwirtschaft',
        Themenschwerpunkt: 'Erlebnisreich Winter',
        Erscheinungstermin: '01.12.2022',
        Anzeigenschluss: '24.11.2022',
        Druckunterlagenschluss: '24.11.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'Dezember',
        Branche: 'Spezialthema',
        Themenschwerpunkt: 'Im Fokus Weststeiermark',
        Erscheinungstermin: '04.12.2022',
        Anzeigenschluss: '23.11.2022',
        Druckunterlagenschluss: '25.11.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Dezember',
        Branche: 'Tourismus + Gastronomie + Freizeitwirtschaft',
        Themenschwerpunkt: 'Erlebnisreich Winter',
        Erscheinungstermin: '08.12.2022',
        Anzeigenschluss: '01.12.2022',
        Druckunterlagenschluss: '01.12.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'Dezember',
        Branche: 'Karriere + Bildung',
        Themenschwerpunkt: 'Aus- und Weiterbildung',
        Erscheinungstermin: '10.12.2022',
        Anzeigenschluss: '25.11.2022',
        Druckunterlagenschluss: '02.12.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Dezember',
        Branche: 'Tourismus + Gastronomie + Freizeitwirtschaft',
        Themenschwerpunkt: 'Erlebnisreich Winter',
        Erscheinungstermin: '15.12.2022',
        Anzeigenschluss: '07.12.2022',
        Druckunterlagenschluss: '07.12.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'Dezember',
        Branche: 'Spezialthema',
        Themenschwerpunkt: 'Stars of Styria',
        Erscheinungstermin: '16.12.2022',
        Anzeigenschluss: '02.12.2022',
        Druckunterlagenschluss: '05.12.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Dezember',
        Branche: 'Tourismus + Gastronomie + Freizeitwirtschaft',
        Themenschwerpunkt: 'Erlebnisreich Winter',
        Erscheinungstermin: '22.12.2022',
        Anzeigenschluss: '15.12.2022',
        Druckunterlagenschluss: '15.12.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'Dezember',
        Branche: 'Tourismus + Gastronomie + Freizeitwirtschaft',
        Themenschwerpunkt: 'Erlebnisreich Winter',
        Erscheinungstermin: '29.12.2022',
        Anzeigenschluss: '22.12.2022',
        Druckunterlagenschluss: '22.12.2022',
        Typ: 'Mitdruck',
      },
    ];
    const FilterDatenSchwerpunkte = computed(() => {
      const branche = activeBranche.value;
      const monat = activeMonat.value;

      return datenSchwerpunkte.filter(schwerpunkt => (branche == 'Bitte Branche auswählen...' || schwerpunkt.Branche == branche) && (monat == 'Bitte Monat auswählen...' || schwerpunkt.Monat == monat));
      // && (activeBranche.value == 'Alle Einträge anzeigen' || schwerpunkt.Branche == activeBranche.value)
    });
    return {
      branchen,
      activeBranche,
      datenSchwerpunkte,
      monate,
      activeMonat,
      FilterDatenSchwerpunkte,
    };
  },
});
