<style lang="scss">
.themenschwerpunkte {
  [class*='col-'] {
  }
  .themen-box {
    margin: 50px 0;
    .col-lg-3 {
      margin-bottom: 30px;
      .bild-box {
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        width: 100%;
        height: 300px;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
        &:after {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          background-color: rgba($color: #000000, $alpha: 0.5);
          -webkit-transition: all 500ms ease;
          -moz-transition: all 500ms ease;
          -ms-transition: all 500ms ease;
          -o-transition: all 500ms ease;
          transition: all 500ms ease;
        }
        .txt {
          width: 100%;
          position: absolute;
          text-align: center;
          left: 0;
          top: 50%;
          z-index: 2;
          color: #fff;
          font-size: 150%;
          height: 40px;
          margin-top: -20px;
        }
        .stretched-link {
          z-index: 10000;
        }
        &:hover {
          &:after {
            background-color: rgba($color: #000000, $alpha: 0);
          }
          .txt {
            opacity: 0;
          }
        }
      }
    }
  }

  .empfehlungen {
    margin-top: 40px;
    margin-bottom: 40px;

    padding: 30px 20px;

    .intro {
      text-align: center;
      h2 {
        margin-bottom: 0px;
        margin-top: 0 !important;
        padding-top: 0;

        border-left: 0;
        &:after {
          margin: 10px auto !important;
        }
      }
      p {
        margin-bottom: 30px;
      }
    }
    .col-lg-2 {
      justify-content: center;
      align-self: flex-start;
      position: relative;

      .icon-box {
        display: block;
        width: 100%;
        img {
          max-width: 100px;
          display: block;
          margin: 0 auto;
        }
      }
    }

    .col-lg-6 {
      h3 {
        margin-bottom: 15px;
      }
      span {
        display: inline-block;
        padding: 15px 25px;
        background: #eee;
        font-size: 130%;
        color: $blue-darker;
      }
      .txt {
        padding: 15px;
        border-left: 3px solid #eee;
      }
    }
  }
  .text-center {
    text-align: center;

    display: block;
  }
  .kalender-outer {
    margin-bottom: 100px;
    .form-group {
      padding: 20px;
      background: $blue-light-v2;
      margin-top: 20px;

      .label-input {
        margin-bottom: 5px;
        padding-left: 3px;
      }
      .form-control {
        height: auto !important;
        padding: 10px !important;
        background-position: center 50px !important;
        border-radius: 2px !important;
        .fa {
          right: 30px;
        }
      }
    }

    .box-outer {
      border: 1px solid #fff;
      //border-bottom: 1px solid #eee;

      padding-top: 20px;
      padding-bottom: 20px;
      margin-left: 10px;
      margin-right: 10px;
      &:nth-child(even) {
        background: #f9f9f9;
      }
      .txt-b {
        text-align: center;

        font-size: 120%;
      }
      .txt-s {
        text-align: right;
        padding-bottom: 5px;
        @media (max-width: $width-lg) {
          //background: #eee;
          //border: 1px solid #fff;
          font-size: 90%;
          padding-bottom: 5px;
          padding-top: 5px;
        }
      }
      .name {
        text-align: left;
        font-size: 140%;
        font-family: $KleineTitel-Bold;

        @media (max-width: $width-lg) {
          text-align: center;
        }
      }
      .branche {
        text-align: left;
        padding-top: 5px;
        padding-bottom: 5px;
        font-family: $KleineSans-Bold;
        @media (max-width: $width-lg) {
          text-align: center;
          padding-bottom: 0px;
          padding-top: 0;
          margin-bottom: 10px;
        }
        span {
          display: inline-block;
          font-family: $KleineSans-Normal;
          margin-right: 5px;
          font-size: 80%;
        }
      }
      .et {
        font-family: $KleineSans-Bold;
        text-align: left;
        line-height: 1.4;
        padding-top: 5px;
        @media (max-width: $width-lg) {
          text-align: center;
        }
        span {
          display: inline-block;
          font-family: $KleineSans-Normal;
          margin-right: 5px;
          font-size: 80%;
        }
      }
      .as {
        @media (max-width: $width-lg) {
          text-align: center;
        }
      }
      .ds {
        @media (max-width: $width-lg) {
          text-align: center;
        }
      }
      .typ {
        @media (max-width: $width-lg) {
          text-align: center;
        }
      }
      .eg {
        text-align: right;
      }
    }
  }
  .no-inputs {
    text-align: center;
    font-family: $KleineTitel-Regular;
    padding: 60px 20px;
    font-size: 140%;
  }
}
</style>

<template>
  <div class="content themenschwerpunkte container">
    <h1>Themenschwerpunkte</h1>
    <p class="intro-txt"><strong>Sie suchen nach dem passenden Themenumfeld für Ihre Werbebotschaft, um diese zielgruppengenau auszuspielen? Kein Problem!</strong></p>
    <p>Themenschwerpunkte der Kleinen Zeitung widmen sich unterschiedlichsten Bereichen und orientieren sich stark an den Interessen und Bedürfnissen unserer User und Leser – ob als abwechslungsreiche Beilage bzw. Bestandteil der Zeitung und/oder als interessanter Content auf kleinezeitung.at.</p>
  </div>
  <div class="bg-blue-light margin-b-m">
    <div class="wrapper-xxl outer-slider">
      <!-- -->
      <div class="print-overview-slider">
        <!-- <Slider :delay="4000" :items="{ 768: 2, 1024: 3, 1400: 3, 1600: 3 }" easing="linear" :speed="500" autoplay noclone>-->
        <Slider :delay="4000" :items="{ 768: 2, 1024: 2, 1400: 3, 1600: 3 }" easing="linear" :speed="500" noclone>
          <template #navigation="{ left, right }">
            <div class="slider-navi-outer">
              <div @click="right"><span class="material-icons arrow-btn arrow-left">arrow_back_ios</span></div>
              <div @click="left"><span class="material-icons arrow-btn arrow-right">arrow_forward_ios</span></div>
            </div>
          </template>

          <template #items>
            <SliderItem>
              <div class="inner-box">
                <div class="img-box">
                  <img src="@/assets/img/img-content/branchenloesungen/bsp-kostprobe.jpg" alt="Titel Exklusiv" class="img-fluid" />
                </div>
                <div class="kat">Lifestyle</div>
                <ModalButton link="modal-lifestyle" class="stretched-link"></ModalButton>
              </div>
            </SliderItem>
            <SliderItem>
              <div class="inner-box">
                <div class="img-box">
                  <img src="@/assets/img/img-content/branchenloesungen/bsp-kompass-lehre.jpg" alt="Titel Exklusiv" class="img-fluid" />
                </div>
                <div class="kat">Bildung &amp; Karriere</div>
                <ModalButton link="modal-bildung-karriere" class="stretched-link"></ModalButton>
              </div>
            </SliderItem>
            <SliderItem>
              <div class="inner-box">
                <div class="img-box">
                  <img src="@/assets/img/img-content/branchenloesungen/bsp-gesundheit.jpg" alt="Titel Exklusiv" class="img-fluid" />
                </div>
                <div class="kat">Gesundheit &amp; Leben</div>
                <ModalButton link="modal-gesundheit-leben" class="stretched-link"></ModalButton>
              </div>
            </SliderItem>

            <SliderItem>
              <div class="inner-box">
                <div class="img-box">
                  <img src="@/assets/img/img-content/branchenloesungen/bsp-moblitaet.jpg" alt="Titel Exklusiv" class="img-fluid" />
                </div>
                <div class="kat">Mobilität</div>
                <ModalButton link="modal-mobilitaet" class="stretched-link"></ModalButton>
              </div>
            </SliderItem>

            <SliderItem>
              <div class="inner-box">
                <div class="img-box">
                  <img src="@/assets/img/img-content/branchenloesungen/bsp-kultursommer.jpg" alt="Titel Exklusiv" class="img-fluid" />
                </div>
                <div class="kat">Kultur</div>
                <ModalButton link="modal-kultur" class="stretched-link"></ModalButton>
              </div>
            </SliderItem>

            <SliderItem>
              <div class="inner-box">
                <div class="img-box">
                  <img src="@/assets/img/img-content/branchenloesungen/bsp-reisezeit.jpg" alt="Titel Exklusiv" class="img-fluid" />
                </div>
                <div class="kat">Tourismus &amp; Reisen</div>
                <ModalButton link="modal-tourismus-reisen" class="stretched-link"></ModalButton>
              </div>
            </SliderItem>
            <SliderItem>
              <div class="inner-box">
                <div class="img-box">
                  <img src="@/assets/img/img-content/branchenloesungen/bsp-primus-ktn.jpg" alt="Titel Exklusiv" class="img-fluid" />
                </div>
                <div class="kat">Wirtschaft und Spezialthemen</div>
                <ModalButton link="modal-wirtschaft" class="stretched-link"></ModalButton>
              </div>
            </SliderItem>
            <SliderItem>
              <div class="inner-box">
                <div class="img-box">
                  <img src="@/assets/img/img-content/branchenloesungen/bsp-bauen-wohnen.jpg" alt="Titel Exklusiv" class="img-fluid" />
                </div>
                <div class="kat">Bauen &amp; Wohnen</div>
                <ModalButton link="modal-bauen-wohnen" class="stretched-link"></ModalButton>
              </div>
            </SliderItem>
          </template>
        </Slider>
      </div>
      <!-- -->
    </div>
  </div>
  <div class="themenschwerpunkte content container">
    <div class="themen-box" style="display:none;">
      <div class="row">
        <div class="col-lg-3 col-md-6">
          <div class="bild-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/sonderprodukte/themenschwerpunkt-bauen.jpg')})` }">
            <div class="txt">Bauen &amp; Wohnen</div>
            <ModalButton link="modal-bauen-wohnen" class="stretched-link"></ModalButton>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="bild-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/sonderprodukte/themenschwerpunkt-bildung.jpg')})` }">
            <div class="txt">Bildung &amp; Karriere</div>
            <ModalButton link="modal-bildung-karriere" class="stretched-link"></ModalButton>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="bild-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/sonderprodukte/themenschwerpunkt-gesundheit.jpg')})` }">
            <div class="txt">Gesundheit &amp; Leben</div>
            <ModalButton link="modal-gesundheit-leben" class="stretched-link"></ModalButton>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="bild-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/sonderprodukte/themenschwerpunkt-kultur.jpg')})` }">
            <div class="txt">Kultur</div>
            <ModalButton link="modal-kultur" class="stretched-link"></ModalButton>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="bild-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/sonderprodukte/themenschwerpunkt-lifestyle.jpg')})` }">
            <div class="txt">Lifestyle</div>
            <ModalButton link="modal-lifestyle" class="stretched-link"></ModalButton>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="bild-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/sonderprodukte/themenschwerpunkt-mobilitaet.jpg')})` }">
            <div class="txt">Mobilität</div>
            <ModalButton link="modal-mobilitaet" class="stretched-link"></ModalButton>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="bild-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/sonderprodukte/themenschwerpunkt-tourismus.jpg')})` }">
            <div class="txt">Tourismus &amp; Reisen</div>
            <ModalButton link="modal-tourismus-reisen" class="stretched-link"></ModalButton>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="bild-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/sonderprodukte/themenschwerpunkt-wirtschaft.jpg')})` }">
            <div class="txt">Wirtschaft und Spezialthemen</div>
            <ModalButton link="modal-wirtschaft" class="stretched-link"></ModalButton>
          </div>
        </div>
      </div>
    </div>
    <Modal link="modal-bauen-wohnen" title="Bauen &amp; Wohnen">
      <h2>Unsere Empfehlungen</h2>
      <ul class="content-ul">
        <li>Wohnen-Journal</li>
        <li>Häuslbauer</li>
        <li>Wohnbauförderungsjournal</li>
        <li>Häuslbauermesse Klagenfurt</li>
        <li>Bauratgeber</li>
        <li>Magazin Garten</li>
        <li>pro:Holz</li>
      </ul>
    </Modal>
    <Modal link="modal-bildung-karriere" title="Bildung &amp; Karriere">
      <h2>Unsere Empfehlungen</h2>
      <ul class="content-ul">
        <li>Bildung Aktuell</li>
        <li>FH Guide</li>
        <li>Fachkräfte</li>
        <li>Wissen was geht.</li>
        <li>Kompass Lehre</li>
        <li>Aus und Weiterbildung</li>
        <li>Top-Jobs im Süden Österreichs</li>
      </ul>
    </Modal>
    <Modal link="modal-gesundheit-leben" title="Gesundheit &amp; Leben">
      <h2>Unsere Empfehlungen</h2>
      <ul class="content-ul">
        <li>Lebenslust</li>
        <li>Pflege</li>
        <li>Ärzteführer</li>
        <li>Themenmagazin Gesundheit</li>
      </ul>
    </Modal>
    <Modal link="modal-kultur" title="Kultur">
      <h2>Unsere Empfehlungen</h2>
      <ul class="content-ul">
        <li>Kultursommer</li>
        <li>Kulturwinter</li>
      </ul>
    </Modal>
    <Modal link="modal-lifestyle" title="Lifestyle">
      <h2>Unsere Empfehlungen</h2>
      <ul class="content-ul">
        <li>Ida</li>
        <li>Kostprobe</li>
        <li>Lebensstil</li>
      </ul>
    </Modal>
    <Modal link="modal-mobilitaet" title="Moblität">
      <h2>Unsere Empfehlungen</h2>
      <ul class="content-ul">
        <li>Mobilitätsjournal</li>
        <li>Innovationsmotor Mobilitätsindustrie</li>
        <li>Themenmagazin Mobilität</li>
      </ul>
    </Modal>
    <Modal link="modal-tourismus-reisen" title="Tourismus &amp; Reisen">
      <h2>Unsere Empfehlungen</h2>
      <ul class="content-ul">
        <li>Reisezeit</li>
        <li>Lust auf Kärnten</li>
        <li>Erlebnisreich</li>
      </ul>
    </Modal>
    <Modal link="modal-wirtschaft" title="Wirtschaft und Spezialthemen ">
      <h2>Unsere Empfehlungen</h2>
      <ul class="content-ul">
        <li>Primus</li>
        <li>Kärntner Vorzeigebetriebe</li>
        <li>Obersteiermark-Journal</li>
        <li>Im Fokus Weststeiermark</li>
        <li>Kärnten Regional</li>
        <li>Stars of Styria</li>
      </ul>
    </Modal>

    <div class="row justify-content-center hinweis-box  margin-t-s margin-b-m ">
      <div class="col-lg-2 bg-left align-self-center"><span class="material-icons">lightbulb</span></div>
      <div class="col-lg-10 bg-right align-self-center">
        <p>
          Individuelle Werbelösungen: Der Mix macht‘s! Je nach Werbeziel empfehlen wir eine Kombination aus Print und Digital, um Ihre Zielgruppe crossmedial zu erreichen und Streuverluste zu minimieren. Die Tarife richten sich dabei nach den allgemein gültigen Tarifen für <router-link :to="{ name: 'Basisplatzierung' }" class="content-link">Basisplatzierung</router-link> bzw. für die jeweiligen
          <router-link :to="{ name: 'DisplayWerbung' }" class="content-link">digitalen Werbeformen.</router-link>
        </p>
      </div>
    </div>

    <div class="kalender-outer">
      <div class="row justify-content-center">
        <div class="col-lg-12 margin-t-m">
          <h2>Themenschwerpunkt-Kalender</h2>
          <p>Sie wollen keinen Themenschwerpunkt mehr verpassen? Mit unserem Themenschwerpunkt-Kalender sind Sie top-informiert: Einfach gewünschte Branche auswählen und sich alle Schwerpunkte mit Erscheinungstermin und wichtigen Infos anzeigen lassen.</p>
        </div>
        <div class="col-lg-6 align-self-center justify-content-center">
          <div class="form-group">
            <select class="form-select form-control" v-model="activeBranche">
              <option v-for="branche of branchen" :key="branche">{{ branche }}</option>
            </select>
          </div>
        </div>
        <div class="col-lg-6 align-self-center justify-content-center">
          <div class="form-group">
            <select class="form-select form-control" v-model="activeMonat">
              <option v-for="monat of monate" :key="monat">{{ monat }}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="box-outer-all" v-if="activeBranche != 'Bitte Branche auswählen...' || activeMonat != 'Bitte Monat auswählen...'">
        <div class="box-outer row" v-for="(schwerpunkt, index) of FilterDatenSchwerpunkte" :key="index">
          <div class="name txt-b col-lg-12 align-self-center"></div>
          <div class="col-lg-12">
            <div class="row">
              <div class="col-lg-6 align-self-center">
                <div class="name txt-b">{{ schwerpunkt.Themenschwerpunkt }}</div>
                <div class="et txt-b">{{ schwerpunkt.Erscheinungstermin }} <span>// Erscheinungstermin </span></div>
                <div class="branche txt-b">{{ schwerpunkt.Branche }} <span>// Branche</span></div>
              </div>
              <div class="col-lg-6 align-self-center">
                <div class="typ txt-s ">Typ: {{ schwerpunkt.Typ }}</div>
                <div class="as txt-s ">Anzeigenschluss: {{ schwerpunkt.Anzeigenschluss }}</div>
                <div class="ds txt-s ">Druckunterlagenschluss: {{ schwerpunkt.Druckunterlagenschluss }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="no-inputs" v-if="FilterDatenSchwerpunkte.length <= 0">
        Keine Ergebnisse.
      </div>
    </div>
    <!--
    <div class="download-pdf">
      <div class="line"><span>DOWNLOAD</span></div>
      <a href="../../pdf/branchenloesungen/Branchenloesungen_Themenschwerpunkte.pdf" target="_blank">
        <div class="row">
          <div class="col-8 justify-content-center align-self-center">
            Themenschwerpunkte 2022
          </div>
          <div class="col-4 justify-content-end align-self-center text-right">
            <span>143 kb // PDF</span>
          </div>
        </div>
      </a>
    </div>-->
    <div class="margin-tb-m"></div>

    <!--<div class="row justify-content-center align-self-center bg-blue-light highlight-box margin-t-m margin-b-m">
      <div class="col-sm-6 d-flex align-self-center">
        <p>Was Interessantes dabei?</p>
      </div>
      <div class="col-sm-6 d-flex align-self-center justify-content-end">
        <div class="btn-std btn-bg-blue-dark">
          <router-link to="kontakt-formular">Jetzt anfragen</router-link>
        </div>
      </div>
    </div>-->

    <h2>Lassen Sie sich inspirieren</h2>
    <p>Werfen Sie einen Blick in unsere Erfolgsbeispiele!</p>
    <div class="row umsetzungs-bsp justify-content-center">
      <div class="col-lg-4 col-md-6">
        <a href="../../pdf/branchenloesungen/Kostprobe.pdf" target="_blank">
          <img src="@/assets/img/img-content/branchenloesungen/bsp-kostprobe.jpg" alt="Kostprobe" class="img-fluid shadow" />
        </a>
      </div>

      <div class="col-lg-4 col-md-6">
        <a href="../../pdf/branchenloesungen/Kompass-Lehre-2021.pdf" target="_blank">
          <img src="@/assets/img/img-content/branchenloesungen/bsp-kompass-lehre.jpg" alt="Kompass Lehre" class="img-fluid shadow" />
        </a>
      </div>
      <div class="col-lg-4 col-md-6">
        <a href="../../pdf/branchenloesungen/Themenmagazin-Gesundheit-2020.pdf" target="_blank">
          <img src="@/assets/img/img-content/branchenloesungen/bsp-gesundheit.jpg" alt="Gesundheit" class="img-fluid shadow" />
        </a>
      </div>
      <div class="col-lg-4 col-md-6">
        <a href="../../pdf/branchenloesungen/Themenmagazin-Moblitaet.pdf" target="_blank">
          <img src="@/assets/img/img-content/branchenloesungen/bsp-moblitaet.jpg" alt="Moblität" class="img-fluid shadow" />
        </a>
      </div>

      <div class="col-lg-4 col-md-6">
        <a href="../../pdf/branchenloesungen/Kultursommer-Kaernten.pdf" target="_blank">
          <img src="@/assets/img/img-content/branchenloesungen/bsp-kultursommer.jpg" alt="Kultursommer" class="img-fluid shadow" />
        </a>
      </div>
      <div class="col-lg-4 col-md-6">
        <a href="../../pdf/branchenloesungen/Reisemantel_November-2021_STMK.pdf" target="_blank">
          <img src="@/assets/img/img-content/branchenloesungen/bsp-reisezeit.jpg" alt="Reisezeit" class="img-fluid shadow" />
        </a>
      </div>
      <div class="col-lg-4 col-md-6">
        <a href="../../pdf/branchenloesungen/Primus-Kaernten.pdf" target="_blank">
          <img src="@/assets/img/img-content/branchenloesungen/bsp-primus-ktn.jpg" alt="Primus Kärnten" class="img-fluid shadow" />
        </a>
      </div>
      <div class="col-lg-4 col-md-6">
        <a href="../../pdf/branchenloesungen/Bauen-und-Wohnen.pdf" target="_blank">
          <img src="@/assets/img/img-content/branchenloesungen/bsp-bauen-wohnen.jpg" alt="Bauen &amp; Wohnen" class="img-fluid shadow" />
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import ModalButton from '@/components/util/ModalButton.vue';
import Modal from '@/components/util/Modal.vue';
import Slider from '@/components/util/Slider.vue';
import SliderItem from '@/components/util/SliderItem.vue';

export default defineComponent({
  components: {
    ModalButton,
    Modal,
    Slider,
    SliderItem,
  },
  setup() {
    const branchen = [
      'Bitte Branche auswählen...',
      'Finanzen + Versicherungen',
      'Gesundheit + Pflege',
      'Gewerbe + Handwerk + Industrie + Chemie + Land- u Forstwirtschaft',
      'Immobilien + Bauen + Wohnen',
      'Karriere + Bildung',
      'Kultur - Veranstaltungen',
      'Lifestyle - Mode - Schmuck - Uhren',
      'Mobilität',
      'Nahrungs- und Genussmittel',
      'Öffentlicher Bereich + Energie',
      'Spezialthema',
      'Tourismus + Gastronomie + Freizeitwirtschaft',
    ];
    const activeBranche = ref('Bitte Branche auswählen...');
    const monate = ['Bitte Monat auswählen...', 'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];
    const activeMonat = ref('Bitte Monat auswählen...');
    const datenSchwerpunkte = [
      {
        Monat: 'Februar',
        Branche: 'Gesundheit + Pflege',
        Themenschwerpunkt: 'Lebenslust',
        Erscheinungstermin: '12.02.2022',
        Anzeigenschluss: '02.02.2022',
        Druckunterlagenschluss: '02.02.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Februar',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Wohnen',
        Erscheinungstermin: '25.02.2022',
        Anzeigenschluss: '18.02.2022',
        Druckunterlagenschluss: '23.02.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Februar',
        Branche: 'Karriere + Bildung',
        Themenschwerpunkt: 'Lehrlinge',
        Erscheinungstermin: '26.02.2022',
        Anzeigenschluss: '16.02.2022',
        Druckunterlagenschluss: '21.02.2022',
        Typ: 'Mitdruck',
      },
      { Monat: 'Februar', Branche: 'Spezialthema', Themenschwerpunkt: 'Pausenzeitung', Erscheinungstermin: '28.02.2022', Anzeigenschluss: '27.12.2021', Druckunterlagenschluss: '10.01.2022', Typ: 'Journal' },
      {
        Monat: 'März',
        Branche: 'Lifestyle - Mode - Schmuck - Uhren',
        Themenschwerpunkt: 'Lebensstil',
        Erscheinungstermin: '02.03.2022',
        Anzeigenschluss: '18.02.2022',
        Druckunterlagenschluss: '25.02.2022',
        Typ: 'Druckstrecke',
      },
      {
        Monat: 'März',
        Branche: 'Mobilität',
        Themenschwerpunkt: 'motionexpo 2022',
        Erscheinungstermin: '04.03.2022',
        Anzeigenschluss: '18.02.2022',
        Druckunterlagenschluss: '25.02.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'März',
        Branche: 'Tourismus + Gastronomie + Freizeitwirtschaft',
        Themenschwerpunkt: 'Reisezeit',
        Erscheinungstermin: '05.03.2022',
        Anzeigenschluss: '23.02.2022',
        Druckunterlagenschluss: '23.02.2022',
        Typ: 'Mantel',
      },
      {
        Monat: 'März',
        Branche: 'Gesundheit + Pflege',
        Themenschwerpunkt: 'Lebenslust',
        Erscheinungstermin: '06.03-2022',
        Anzeigenschluss: '23.02.2022',
        Druckunterlagenschluss: '01.03.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'März',
        Branche: 'Karriere + Bildung',
        Themenschwerpunkt: 'FH Guide',
        Erscheinungstermin: '09.03.2022',
        Anzeigenschluss: '25..02.2022',
        Druckunterlagenschluss: '04.03.2022',
        Typ: 'Druckstrecke',
      },
      {
        Monat: 'März',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Bauratgeber',
        Erscheinungstermin: '16.03.2022',
        Anzeigenschluss: '18.02.2022',
        Druckunterlagenschluss: '23.02.2022',
        Typ: 'Ratgeber',
      },
      {
        Monat: 'März',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Preisspiegel Serie',
        Erscheinungstermin: '18.03.2022',
        Anzeigenschluss: '07.03.2022',
        Druckunterlagenschluss: '16.03.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'März',
        Branche: 'Gesundheit + Pflege',
        Themenschwerpunkt: 'Pflege',
        Erscheinungstermin: '20.03.2022',
        Anzeigenschluss: '09.03.2022',
        Druckunterlagenschluss: '14.03.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'März',
        Branche: 'Karriere + Bildung',
        Themenschwerpunkt: 'Recruiting Day',
        Erscheinungstermin: '23.03.2022',
        Anzeigenschluss: '11.03.2022',
        Druckunterlagenschluss: '16.03.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'März',
        Branche: 'Spezialthema',
        Themenschwerpunkt: 'Mantel Obersteiermark',
        Erscheinungstermin: '24.03.2022',
        Anzeigenschluss: '03.03.2022',
        Druckunterlagenschluss: '10.03.2022',
        Typ: 'Mantel',
      },
      {
        Monat: 'März',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Wohnen',
        Erscheinungstermin: '25.03.2022',
        Anzeigenschluss: '18.03.2022',
        Druckunterlagenschluss: '23.03.2022',
        Typ: 'Journal',
      },
      { Monat: 'März', Branche: 'Beratung & Wirtschafts- und Rechtsdienste', Themenschwerpunkt: 'Primus', Erscheinungstermin: '26.03.2022', Anzeigenschluss: '16.03.2022', Druckunterlagenschluss: '16.03.2022', Typ: 'Journal' },
      { Monat: 'März', Branche: 'Spezialthema', Themenschwerpunkt: 'Frühlings- . Sommerbeilage Kinderzeitung', Erscheinungstermin: '26.03.2022', Anzeigenschluss: '14.02.2022', Druckunterlagenschluss: '21.02.2022', Typ: 'Journal' },
      {
        Monat: 'März',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Wohnbauförderungsjournal',
        Erscheinungstermin: '27.03.2022',
        Anzeigenschluss: '01.03.2022',
        Druckunterlagenschluss: '11.03.2022',
        Typ: 'Magazin Zeitungspapier',
      },
      {
        Monat: 'März',
        Branche: 'Gewerbe + Handwerk + Industrie + Chemie + Land- u Forstwirtschaft',
        Themenschwerpunkt: 'Kärntner Vorzeigebetriebe',
        Erscheinungstermin: '29.03.2022',
        Anzeigenschluss: '16.03.2022',
        Druckunterlagenschluss: '16.03.2022',
        Typ: 'Mitdruck',
      },
      { Monat: 'April', Branche: 'Spezialthema', Themenschwerpunkt: 'Pausenzeitung', Erscheinungstermin: '04.04.2022', Anzeigenschluss: '31.01.2022', Druckunterlagenschluss: '14.02.2022', Typ: 'Journal' },
      {
        Monat: 'April',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Magazin Leben im Garten',
        Erscheinungstermin: '06.04.2022',
        Anzeigenschluss: '18.03.2022',
        Druckunterlagenschluss: '25.03.2022',
        Typ: 'Magazin Zeitungspapier',
      },
      {
        Monat: 'April',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Preisspiegel Serie',
        Erscheinungstermin: '08.04.2022',
        Anzeigenschluss: '28.03.2022',
        Druckunterlagenschluss: '06.04.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'April',
        Branche: 'Gesundheit + Pflege',
        Themenschwerpunkt: 'Lebenslust',
        Erscheinungstermin: '10.04.2022',
        Anzeigenschluss: '30.03.2022',
        Druckunterlagenschluss: '05.04.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'April',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'pro:Holz',
        Erscheinungstermin: '17.04.2022',
        Anzeigenschluss: '21.03.2022',
        Druckunterlagenschluss: '28.03.2022',
        Typ: 'Magazin LWC',
      },
      {
        Monat: 'April',
        Branche: 'Lifestyle - Mode - Schmuck - Uhren',
        Themenschwerpunkt: 'Magazin Lebensstil',
        Erscheinungstermin: '20.04.2022',
        Anzeigenschluss: '01.04.2022',
        Druckunterlagenschluss: '04.04.2022',
        Typ: 'Magazin Zeitungspapier',
      },
      {
        Monat: 'April',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Preisspiegel Serie',
        Erscheinungstermin: '22.04.2022',
        Anzeigenschluss: '11.04.2022',
        Druckunterlagenschluss: '20.04.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'April',
        Branche: 'Spezialthema',
        Themenschwerpunkt: 'Im Fokus Weststeiermark',
        Erscheinungstermin: '24.04.2022',
        Anzeigenschluss: '12.04.2022',
        Druckunterlagenschluss: '19.04.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'April',
        Branche: 'Tourismus + Gastronomie + Freizeitwirtschaft',
        Themenschwerpunkt: 'Lust auf Kärnten',
        Erscheinungstermin: '26.04.2022',
        Anzeigenschluss: '15.04.2022',
        Druckunterlagenschluss: '22.04.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'April',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Wohnen',
        Erscheinungstermin: '29.04.2022',
        Anzeigenschluss: '22.04.2022',
        Druckunterlagenschluss: '27.04.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Mai',
        Branche: 'Nahrungs- und Genussmittel',
        Themenschwerpunkt: 'Magazin Kostprobe',
        Erscheinungstermin: '01.05.2022',
        Anzeigenschluss: '11.04.2022',
        Druckunterlagenschluss: '15.04.2022',
        Typ: 'Magazin Zeitungspapier',
      },
      {
        Monat: 'Mai',
        Branche: 'Gesundheit + Pflege',
        Themenschwerpunkt: 'Lebenslust',
        Erscheinungstermin: '08.05.2022',
        Anzeigenschluss: '27.04.2022',
        Druckunterlagenschluss: '03.05.2022',
        Typ: 'Journal',
      },
      { Monat: 'Mai', Branche: 'Spezialthema', Themenschwerpunkt: 'Pausenzeitung', Erscheinungstermin: '09.05.2022', Anzeigenschluss: '07.03.2022', Druckunterlagenschluss: '21.03.2022', Typ: 'Journal' },
      {
        Monat: 'Mai',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Preisspiegel Serie',
        Erscheinungstermin: '13.05.2022',
        Anzeigenschluss: '02.05.2022',
        Druckunterlagenschluss: '11.05.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'Mai',
        Branche: 'Kultur - Veranstaltungen',
        Themenschwerpunkt: 'Kultursommer',
        Erscheinungstermin: '15.05.2022',
        Anzeigenschluss: '19.04.2022',
        Druckunterlagenschluss: '25.04.2022',
        Typ: 'Magazin Zeitungspapier',
      },
      {
        Monat: 'Mai',
        Branche: 'Karriere + Bildung',
        Themenschwerpunkt: 'Fachkräfte',
        Erscheinungstermin: '21.05.2022',
        Anzeigenschluss: '06.05.2022',
        Druckunterlagenschluss: '13.05.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Mai',
        Branche: 'Gewerbe + Handwerk + Industrie + Chemie + Land- u Forstwirtschaft',
        Themenschwerpunkt: 'Kärntner Vorzeigebetriebe',
        Erscheinungstermin: '24.05.2022',
        Anzeigenschluss: '11.05.2022',
        Druckunterlagenschluss: '11.05.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'Mai',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Wohnen',
        Erscheinungstermin: '27.05.2022',
        Anzeigenschluss: '20.05.2022',
        Druckunterlagenschluss: '25.05.2022',
        Typ: 'Journal',
      },
      { Monat: 'Mai', Branche: 'Beratung & Wirtschafts- und Rechtsdienste', Themenschwerpunkt: 'Primus', Erscheinungstermin: '28.05.2022', Anzeigenschluss: '18.05.2022', Druckunterlagenschluss: '18.05.2022', Typ: 'Journal' },
      {
        Monat: 'Juni',
        Branche: 'Lifestyle - Mode - Schmuck - Uhren',
        Themenschwerpunkt: 'Lebensstil',
        Erscheinungstermin: '01.06.2022',
        Anzeigenschluss: '20.05.2022',
        Druckunterlagenschluss: '27.05.2022',
        Typ: 'Druckstrecke',
      },
      {
        Monat: 'Juni',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Preisspiegel Serie',
        Erscheinungstermin: '03.06.2022',
        Anzeigenschluss: '23.05.2022',
        Druckunterlagenschluss: '01.06.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'Juni',
        Branche: 'Tourismus + Gastronomie + Freizeitwirtschaft',
        Themenschwerpunkt: 'Reisezeit',
        Erscheinungstermin: '04.06.2022',
        Anzeigenschluss: '24.05.2022',
        Druckunterlagenschluss: '24.05.2022',
        Typ: 'Mantel',
      },
      { Monat: 'Juni', Branche: 'Spezialthema', Themenschwerpunkt: 'Pausenzeitung', Erscheinungstermin: '07.06.2022', Anzeigenschluss: '28.04.2022', Druckunterlagenschluss: '11.05.2022', Typ: 'Journal' },
      {
        Monat: 'Juni',
        Branche: 'Gesundheit + Pflege',
        Themenschwerpunkt: 'Lebenslust',
        Erscheinungstermin: '12.06.2022',
        Anzeigenschluss: '01.06.2022',
        Druckunterlagenschluss: '07.06.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Juni',
        Branche: 'Gewerbe + Handwerk + Industrie + Chemie + Land- u Forstwirtschaft',
        Themenschwerpunkt: 'Steirische Vorzeigebetriebe',
        Erscheinungstermin: '14.06.2022',
        Anzeigenschluss: '31.05.2022',
        Druckunterlagenschluss: '31.05.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'Juni',
        Branche: 'Spezialthema',
        Themenschwerpunkt: 'Mantel Obersteiermark',
        Erscheinungstermin: '23.06.2022',
        Anzeigenschluss: '02.06.2022',
        Druckunterlagenschluss: '09.06.2022',
        Typ: 'Mantel',
      },
      {
        Monat: 'Juni',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Wohnen',
        Erscheinungstermin: '24.06.2022',
        Anzeigenschluss: '17.06.2022',
        Druckunterlagenschluss: '22.06.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Juli',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Preisspiegel Serie',
        Erscheinungstermin: '01.07.2022',
        Anzeigenschluss: '20.06.2022',
        Druckunterlagenschluss: '29.06.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'Juli',
        Branche: 'Lifestyle - Mode - Schmuck - Uhren',
        Themenschwerpunkt: 'Ida',
        Erscheinungstermin: '09.07.2022',
        Anzeigenschluss: '25.05.2022',
        Druckunterlagenschluss: '01.06.2022',
        Typ: 'Magazin LWC',
      },
      {
        Monat: 'Juli',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Wohnen',
        Erscheinungstermin: '29.07.2022',
        Anzeigenschluss: '22.07.2022',
        Druckunterlagenschluss: '27.07.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'August',
        Branche: 'Tourismus + Gastronomie + Freizeitwirtschaft',
        Themenschwerpunkt: 'Lust auf Kärnten',
        Erscheinungstermin: '17.08.2022',
        Anzeigenschluss: '05.08.2022',
        Druckunterlagenschluss: '12.08.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'August',
        Branche: 'Gewerbe + Handwerk + Industrie + Chemie + Land- u Forstwirtschaft',
        Themenschwerpunkt: 'Kärntner Vorzeigebetriebe',
        Erscheinungstermin: '23.08.2022',
        Anzeigenschluss: '10.08.2022',
        Druckunterlagenschluss: '10.08.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'August',
        Branche: 'Lifestyle - Mode - Schmuck - Uhren',
        Themenschwerpunkt: 'Lebensstil',
        Erscheinungstermin: '24.08.2022',
        Anzeigenschluss: '12.08.2022',
        Druckunterlagenschluss: '19.08.2022',
        Typ: 'Druckstrecke',
      },
      {
        Monat: 'August',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Wohnen',
        Erscheinungstermin: '26.08.2022',
        Anzeigenschluss: '19.08.2022',
        Druckunterlagenschluss: '24.08.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'September',
        Branche: 'Tourismus + Gastronomie + Freizeitwirtschaft',
        Themenschwerpunkt: 'Reisezeit',
        Erscheinungstermin: '03.09.2022',
        Anzeigenschluss: '24.08.2022',
        Druckunterlagenschluss: '24.08.2022',
        Typ: 'Mantel',
      },
      {
        Monat: 'September',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Preisspiegel Serie',
        Erscheinungstermin: '09.09.2022',
        Anzeigenschluss: '29.08.2022',
        Druckunterlagenschluss: '07.09.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'September',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Preisspiegel Serie',
        Erscheinungstermin: '09.09.2022',
        Anzeigenschluss: '29.08.2022',
        Druckunterlagenschluss: '07.09.2022',
        Typ: 'Mitdruck',
      },
      { Monat: 'September', Branche: 'Spezialthema', Themenschwerpunkt: 'Herbst- . Winterbeilage Kinderzeitung', Erscheinungstermin: '10.09.2022', Anzeigenschluss: '01.08.2022', Druckunterlagenschluss: '08.08.2022', Typ: 'Journal' },
      {
        Monat: 'September',
        Branche: 'Gesundheit + Pflege',
        Themenschwerpunkt: 'Lebenslust',
        Erscheinungstermin: '11.09.2022',
        Anzeigenschluss: '31.08.2022',
        Druckunterlagenschluss: '06.09.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'September',
        Branche: 'Gewerbe + Handwerk + Industrie + Chemie + Land- u Forstwirtschaft',
        Themenschwerpunkt: 'Steirische Vorzeigebetriebe',
        Erscheinungstermin: '13.09.2022',
        Anzeigenschluss: '30.08.2022',
        Druckunterlagenschluss: '30.08.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'September',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Preisspiegel Serie',
        Erscheinungstermin: '16.09.2022',
        Anzeigenschluss: '05.09.2022',
        Druckunterlagenschluss: '14.09.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'September',
        Branche: 'Spezialthema',
        Themenschwerpunkt: 'Mantel Obersteiermark',
        Erscheinungstermin: '22.09.2022',
        Anzeigenschluss: '01.09.2022',
        Druckunterlagenschluss: '08.09.2022',
        Typ: 'Mantel',
      },
      { Monat: 'September', Branche: 'Beratung & Wirtschafts- und Rechtsdienste', Themenschwerpunkt: 'Primus', Erscheinungstermin: '24.09.2022', Anzeigenschluss: '14.09.2022', Druckunterlagenschluss: '14.09.2022', Typ: 'Journal' },
      {
        Monat: 'September',
        Branche: 'Karriere + Bildung',
        Themenschwerpunkt: 'Wissen was geht - Südsüdwest . Ennstal',
        Erscheinungstermin: '29.09.2022',
        Anzeigenschluss: '16.09.2022',
        Druckunterlagenschluss: '14.09.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'September',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Wohnen',
        Erscheinungstermin: '30.09.2022',
        Anzeigenschluss: '23.09.2022',
        Druckunterlagenschluss: '28.09.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Oktober',
        Branche: 'Karriere + Bildung',
        Themenschwerpunkt: 'Gründermesse 2022',
        Erscheinungstermin: '01.10.2022',
        Anzeigenschluss: '16.09.2022',
        Druckunterlagenschluss: '23.09.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Oktober',
        Branche: 'Nahrungs- und Genussmittel',
        Themenschwerpunkt: 'Magazin Kostprobe',
        Erscheinungstermin: '02.10.2022',
        Anzeigenschluss: '12.09.2022',
        Druckunterlagenschluss: '16.09.2022',
        Typ: 'Magazin Zeitungspapier',
      },
      {
        Monat: 'Oktober',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Preisspiegel Serie',
        Erscheinungstermin: '07.10.2022',
        Anzeigenschluss: '26.09.2022',
        Druckunterlagenschluss: '05.10.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'Oktober',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Preisspiegel Serie',
        Erscheinungstermin: '07.10.2022',
        Anzeigenschluss: '26.09.2022',
        Druckunterlagenschluss: '05.10.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'Oktober',
        Branche: 'Gesundheit + Pflege',
        Themenschwerpunkt: 'Lebenslust',
        Erscheinungstermin: '09.10.2022',
        Anzeigenschluss: '28.09.2022',
        Druckunterlagenschluss: '04.10.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Oktober',
        Branche: 'Mobilität',
        Themenschwerpunkt: 'Innovationsmotor Mobilitätsindustrie',
        Erscheinungstermin: '12.10.2022',
        Anzeigenschluss: '07.09.2022',
        Druckunterlagenschluss: '14.09.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Oktober',
        Branche: 'Gesundheit + Pflege',
        Themenschwerpunkt: 'Pflege',
        Erscheinungstermin: '16.10.2022',
        Anzeigenschluss: '05.10.2022',
        Druckunterlagenschluss: '10.10.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Oktober',
        Branche: 'Karriere + Bildung',
        Themenschwerpunkt: 'Wissen was geht - Südost',
        Erscheinungstermin: '21.10.2022',
        Anzeigenschluss: '07.10.2022',
        Druckunterlagenschluss: '14.10.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Oktober',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Preisspiegel Serie',
        Erscheinungstermin: '21.10.2022',
        Anzeigenschluss: '10.10.2022',
        Druckunterlagenschluss: '19.10.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'Oktober',
        Branche: 'Karriere + Bildung',
        Themenschwerpunkt: 'Kompass Lehre',
        Erscheinungstermin: '22.10.2022',
        Anzeigenschluss: '30.09.2022',
        Druckunterlagenschluss: '23.09.2022',
        Typ: 'Magazin Zeitungspapier',
      },
      {
        Monat: 'Oktober',
        Branche: 'Gesundheit + Pflege',
        Themenschwerpunkt: 'Ärzteführer',
        Erscheinungstermin: '25.10.2022',
        Anzeigenschluss: '23.09.2022',
        Druckunterlagenschluss: '07.10.2022',
        Typ: 'Ratgeber',
      },
      {
        Monat: 'Oktober',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Wohnen',
        Erscheinungstermin: '28.10.2022',
        Anzeigenschluss: '20.10.2022',
        Druckunterlagenschluss: '25.10.2022',
        Typ: 'Journal',
      },
      { Monat: 'Oktober', Branche: 'Beratung & Wirtschafts- und Rechtsdienste', Themenschwerpunkt: 'Primus', Erscheinungstermin: '29.10.2022', Anzeigenschluss: '19.10.2022', Druckunterlagenschluss: '19.10.2022', Typ: 'Journal' },
      {
        Monat: 'Oktober',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'pro:Holz',
        Erscheinungstermin: '30.10.2022',
        Anzeigenschluss: '03.10.2022',
        Druckunterlagenschluss: '10.10.2022',
        Typ: 'Magazin LWC',
      },
      {
        Monat: 'November',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Preisspiegel Serie',
        Erscheinungstermin: '04.11.2022',
        Anzeigenschluss: '24.10.2022',
        Druckunterlagenschluss: '02.11.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'November',
        Branche: 'Tourismus + Gastronomie + Freizeitwirtschaft',
        Themenschwerpunkt: 'Reisezeit',
        Erscheinungstermin: '05.11.2022',
        Anzeigenschluss: '24.10.2022',
        Druckunterlagenschluss: '24.10.2022',
        Typ: 'Mantel',
      },
      {
        Monat: 'November',
        Branche: 'Gesundheit + Pflege',
        Themenschwerpunkt: 'Lebenslust',
        Erscheinungstermin: '06.11.2022',
        Anzeigenschluss: '25.10.2022',
        Druckunterlagenschluss: '31.10.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'November',
        Branche: 'Kultur - Veranstaltungen',
        Themenschwerpunkt: 'Kulturwinter',
        Erscheinungstermin: '13.11.2022',
        Anzeigenschluss: '17.10.2022',
        Druckunterlagenschluss: '24.10.2022',
        Typ: 'Magazin Zeitungspapier',
      },
      {
        Monat: 'November',
        Branche: 'Gewerbe + Handwerk + Industrie + Chemie + Land- u Forstwirtschaft',
        Themenschwerpunkt: 'Steirische Vorzeigebetriebe',
        Erscheinungstermin: '15.11.2022',
        Anzeigenschluss: '31.10.2022',
        Druckunterlagenschluss: '31.10.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'November',
        Branche: 'Spezialthema',
        Themenschwerpunkt: 'Mantel Obersteiermark',
        Erscheinungstermin: '17.11.2022',
        Anzeigenschluss: '27.10.2022',
        Druckunterlagenschluss: '03.11.2022',
        Typ: 'Mantel',
      },
      {
        Monat: 'November',
        Branche: 'Gesundheit + Pflege',
        Themenschwerpunkt: 'Themenmagazin Gesundheit',
        Erscheinungstermin: '19.11.2022',
        Anzeigenschluss: '28.10.2022',
        Druckunterlagenschluss: '04.11.2022',
        Typ: 'Magazin Zeitungspapier',
      },
      {
        Monat: 'November',
        Branche: 'Immobilien + Bauen + Wohnen',
        Themenschwerpunkt: 'Wohnen',
        Erscheinungstermin: '25.11.2022',
        Anzeigenschluss: '18.11.2022',
        Druckunterlagenschluss: '23.11.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'November',
        Branche: 'Karriere + Bildung',
        Themenschwerpunkt: 'Top-Jobs im Süden Österreichs',
        Erscheinungstermin: '26.11.2022',
        Anzeigenschluss: '11.11.2022',
        Druckunterlagenschluss: '18.11.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'November',
        Branche: 'Spezialthema',
        Themenschwerpunkt: 'Ida',
        Erscheinungstermin: '27.11.2022',
        Anzeigenschluss: '12.10.2022',
        Druckunterlagenschluss: '19.10.2022',
        Typ: 'Magazin LWC',
      },
      {
        Monat: 'November',
        Branche: 'Gewerbe + Handwerk + Industrie + Chemie + Land- u Forstwirtschaft',
        Themenschwerpunkt: 'Kärntner Vorzeigebetriebe',
        Erscheinungstermin: '29.11.2022',
        Anzeigenschluss: '16.11.2022',
        Druckunterlagenschluss: '16.11.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'Dezember',
        Branche: 'Tourismus + Gastronomie + Freizeitwirtschaft',
        Themenschwerpunkt: 'Erlebnisreich Winter',
        Erscheinungstermin: '01.12.2022',
        Anzeigenschluss: '24.11.2022',
        Druckunterlagenschluss: '24.11.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'Dezember',
        Branche: 'Spezialthema',
        Themenschwerpunkt: 'Im Fokus Weststeiermark',
        Erscheinungstermin: '04.12.2022',
        Anzeigenschluss: '23.11.2022',
        Druckunterlagenschluss: '25.11.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Dezember',
        Branche: 'Tourismus + Gastronomie + Freizeitwirtschaft',
        Themenschwerpunkt: 'Erlebnisreich Winter',
        Erscheinungstermin: '08.12.2022',
        Anzeigenschluss: '01.12.2022',
        Druckunterlagenschluss: '01.12.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'Dezember',
        Branche: 'Karriere + Bildung',
        Themenschwerpunkt: 'Aus- und Weiterbildung',
        Erscheinungstermin: '10.12.2022',
        Anzeigenschluss: '25.11.2022',
        Druckunterlagenschluss: '02.12.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Dezember',
        Branche: 'Tourismus + Gastronomie + Freizeitwirtschaft',
        Themenschwerpunkt: 'Erlebnisreich Winter',
        Erscheinungstermin: '15.12.2022',
        Anzeigenschluss: '07.12.2022',
        Druckunterlagenschluss: '07.12.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'Dezember',
        Branche: 'Spezialthema',
        Themenschwerpunkt: 'Stars of Styria',
        Erscheinungstermin: '16.12.2022',
        Anzeigenschluss: '02.12.2022',
        Druckunterlagenschluss: '05.12.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Dezember',
        Branche: 'Tourismus + Gastronomie + Freizeitwirtschaft',
        Themenschwerpunkt: 'Erlebnisreich Winter',
        Erscheinungstermin: '22.12.2022',
        Anzeigenschluss: '15.12.2022',
        Druckunterlagenschluss: '15.12.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'Dezember',
        Branche: 'Tourismus + Gastronomie + Freizeitwirtschaft',
        Themenschwerpunkt: 'Erlebnisreich Winter',
        Erscheinungstermin: '29.12.2022',
        Anzeigenschluss: '22.12.2022',
        Druckunterlagenschluss: '22.12.2022',
        Typ: 'Mitdruck',
      },
    ];
    const FilterDatenSchwerpunkte = computed(() => {
      const branche = activeBranche.value;
      const monat = activeMonat.value;

      return datenSchwerpunkte.filter(schwerpunkt => (branche == 'Bitte Branche auswählen...' || schwerpunkt.Branche == branche) && (monat == 'Bitte Monat auswählen...' || schwerpunkt.Monat == monat));
      // && (activeBranche.value == 'Alle Einträge anzeigen' || schwerpunkt.Branche == activeBranche.value)
    });
    return {
      branchen,
      activeBranche,
      datenSchwerpunkte,
      monate,
      activeMonat,
      FilterDatenSchwerpunkte,
    };
  },
});
</script>
